import { Stack, Typography } from '@mui/material';
import { StackProps } from '@mui/system';
import { useRef } from 'react';
import { Props } from '../@types/title';

interface IContainer {
  children: any;
  noMarginBotton?: boolean;
}

export function Title({ nome }: Props) {
  return (
    <Typography variant="h4" align="center" mt={2} mb={2}>
      {nome}
    </Typography>
  );
}

export function Container({ children, noMarginBotton }: IContainer) {
  return (
    <Stack m="5%" mt="30px" mb={noMarginBotton ? '30px' : ''} style={{ overflow: 'hidden' }}>
      {children}
    </Stack>
  );
}

export const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
};

export const GlobalRefs = () => {
  const contactRef = useRef<HTMLDivElement>(null);
  const differencesRef = useRef<HTMLDivElement>(null);
  const adsRef = useRef<HTMLDivElement>(null);
  const coverRef = useRef<HTMLDivElement>(null);

  return {
    contactRef,
    adsRef,
    coverRef,
    differencesRef,
  };
};

interface ICenterizedCompContainer extends StackProps {
  children: any;
}

export function CenterizedCompContainer({ children, ...props }: ICenterizedCompContainer) {
  return (
    <Stack
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      {...props}
    >
      {children}
    </Stack>
  );
}

export function GrayBackgroundContainer({ children }: ICenterizedCompContainer) {
  return <Stack style={{ backgroundColor: '#f0f0f0', borderRadius: '20px' }}>{children}</Stack>;
}

export const CompanyName: string = 'Full Code Software e Tecnologia';

export const MobileResolution: string = '750px';

export function TextContainer({ children }: ICenterizedCompContainer) {
  return <Stack maxWidth="1200px"> {children} </Stack>;
}
