import { Stack, Typography } from '@mui/material';
import { StyledCopyright } from './styles';

export default function CopyrightSection() {
  return (
    <Stack style={{ backgroundColor: '#FFF' }}>
      <StyledCopyright>
        <Typography gutterBottom variant="h6">
          &copy; Copyright 2025 - Full Code Software e Tecnologia - Todos os direitos reservados
        </Typography>
      </StyledCopyright>
    </Stack>
  );
}
