import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import './locales/i18n';
import './utils/mapboxgl';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SpeedInsights } from '@vercel/speed-insights/react';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AuthProvider } from './auth/JwtContext';
import { MotionLazyContainer } from './components/animate';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import CookiesAdvice from './features/components/cokkiesAdvise';
import ThemeLocalization from './locales';
import { persistor, store } from './redux/store';
import Router from './routes';
import ThemeProvider from './theme';

export default function App() {
  const TRACKING_ID = 'G-KP5M3WBQHZ';

  ReactGA.initialize(TRACKING_ID);

  return (
    <HelmetProvider>
      <AuthProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <ScrollToTop />
                  <MotionLazyContainer>
                    <ThemeProvider>
                      <ThemeSettings>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <StyledChart />
                            <CookiesAdvice />
                            <Router />
                            <SpeedInsights />
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeSettings>
                    </ThemeProvider>
                  </MotionLazyContainer>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}
