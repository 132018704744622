import { PATH_COURSES, PATH_PAGE } from 'src/routes/paths';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="tabler:home-filled" />,
    path: PATH_PAGE.home,
  },
  {
    title: 'Blog',
    icon: <Iconify icon="fontisto:blogger" />,
    path: PATH_PAGE.blog,
  },

  {
    title: 'Curso Front-End (Tele-Presencial)',
    icon: <Iconify icon="material-symbols:play-lesson" />,
    path: PATH_COURSES.aovivo,
  },
  {
    title: 'Quem Somos',
    icon: <Iconify icon="mdi:about" />,
    path: PATH_PAGE.sobreNos,
  },
  {
    title: 'Trabalhe Conosco',
    icon: <Iconify icon="material-symbols:work" />,
    path: PATH_PAGE.trabalheConosco,
  },
];

export default navConfig;
