import { Navigate, useRoutes } from 'react-router-dom';
import CursosOnline from 'src/features/pages/cursos/cursosOnline';
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
import {
  Backend,
  Blog,
  ComingSoonPage,
  CursosAoVivo,
  Dev,
  DevOps,
  FrontEnd,
  HomePage,
  MaintenancePage,
  Page404,
  SobreNos,
  WorkUs,
} from './elements';
import { PATH_ARTICLES, PATH_COURSES, PATH_PAGE } from './paths';

// -------------------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        {
          path: PATH_COURSES.aovivo,
          element: <CursosAoVivo />,
        },
        {
          path: PATH_COURSES.online,
          element: <CursosOnline />,
        },
        {
          path: PATH_ARTICLES.frontend,
          element: <FrontEnd />,
        },
        {
          path: PATH_ARTICLES.backend,
          element: <Backend />,
        },
        {
          path: PATH_ARTICLES.devops,
          element: <DevOps />,
        },
        {
          path: PATH_PAGE.sobreNos,
          element: <SobreNos />,
        },
        {
          path: PATH_PAGE.trabalheConosco,
          element: <WorkUs />,
        },
        {
          path: 'blog',
          element: <Blog />,
        },
        {
          path: 'dev',
          element: <Dev />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'embreve', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '404', element: <Page404 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
