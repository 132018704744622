import { Stack, styled } from '@mui/material';

// -------------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  '@media (max-width: 750px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}));

export const StyledImage = styled('img')({
  width: '350px',
  objectFit: 'cover',
  height: '100%',

  '@media (max-width: 750px)': {
    width: '100%',
    height: '350px'
  },
});
