import { Box, BoxProps, Link } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

// ----------------------------------------------------------------

export const LogoLight = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="/logo/light.png"
        alt="Logo da Full Code"
        sx={{
          width: { xs: 180, sm: 230 },
          height: { xs: 50, sm: 60 },
          cursor: 'pointer',
          ...sx,
        }}
        ref={ref}
        {...other}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);
