import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { COLOR } from 'src/theme/custom/colors';

export default function Countdown() {
  const getRemainingTime = useCallback(() => {
    const targetDate = new Date('2025-01-17T00:00:00');
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    return timeDifference;
  }, []);

  const [counter, setCounter] = useState(Math.floor(getRemainingTime() / 1000));

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = getRemainingTime();
      const remainingSeconds = Math.floor(remainingTime / 1000);
      setCounter(remainingSeconds);

      if (remainingSeconds <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [getRemainingTime]);

  const formatTime = (seconds: any) => {
    const SECONDS_IN_A_DAY = 86400;
    const days = Math.floor(seconds / SECONDS_IN_A_DAY);
    const hours = Math.floor((seconds % SECONDS_IN_A_DAY) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return { days, hours, minutes, secs };
  };

  const { days, hours, minutes, secs } = formatTime(counter);

  let urgencyMessage = '';
  let urgencyColor = 'white';

  if (counter <= 0) {
    urgencyMessage = 'As inscrições gratuitas acabaram!';
    urgencyColor = 'gray';
  } else if (counter <= 3600) {
    urgencyMessage = 'Últimas horas! As inscrições gratuitas estão prestes a acabar:';
    urgencyColor = 'red';
  } else if (counter <= 86400) {
    urgencyMessage = 'Corra! Restam apenas:';
    urgencyColor = 'orange';
  } else {
    urgencyMessage = 'Corra! As iscrições gratuitas acabam em:';
    urgencyColor = 'white';
  }

  return (
    <div
      style={{
        color: urgencyColor,
        fontSize: '1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Typography variant="h5" align="center" color="red">
        {urgencyMessage}
      </Typography>

      <Typography variant="h3" align="center" color={COLOR.orange}>
        {days}d : {hours}h : {minutes}m : {secs}s
      </Typography>
    </div>
  );
}
