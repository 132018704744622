import { Button, Stack, Typography } from '@mui/material';
import SocialMedia from '../components/socialMedia';
import { socialMediaContent } from '../elements/footer/content';
import { StyledContainer, StyledContent } from './styles';

// ---------------------------------------------------------------------------

export default function FooterMobile() {
  const light = '#FFF';

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledContainer>
      {/* ======================================================================================================= */}

      <Button
        variant="contained"
        onClick={handleScrollToTop}
        sx={{ width: '200px', marginTop: '30px' }}
      >
        Voltar ao Topo
      </Button>

      <Stack
        id="Session 2"
        style={{
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: '30px',
          marginTop: '30px',
        }}
      >
        <Typography gutterBottom variant="h5" component="div" align="center" color={light}>
          Redes Sociais
        </Typography>

        <SocialMedia content={socialMediaContent} />
      </Stack>

      {/* ======================================================================================================= */}

      {/* <Stack
        id="Session 3"
        style={{ alignItems: 'center', textAlign: 'center', marginBottom: '30px' }}
      >
        <Typography gutterBottom variant="h5" component="div" align="center" color={light}>
          Endereço
        </Typography>

        <Typography gutterBottom variant="body1" component="div" align="center" color={light}>
          Rua Presidente Médice, Nº 113, <br /> Centro, Vilhena-RO, CEP: 76980-096
        </Typography>
      </Stack> */}
      {/* ======================================================================================================= */}

      <Stack
        id="Session 4"
        style={{ alignItems: 'center', textAlign: 'center', marginBottom: '30px' }}
      >
        <Typography gutterBottom variant="h5" component="div" align="center" color={light}>
          Contato
        </Typography>

        <Typography gutterBottom variant="body1" component="div" align="center" color={light}>
          Telefone: (69) 99252-5479 <br />
          E-mail: gerencia@fullcodedev.com
        </Typography>
      </Stack>

      {/* ======================================================================================================= */}

      <StyledContent id="Session 5" style={{ alignItems: 'center', textAlign: 'center' }}>
        <Typography gutterBottom variant="body1" component="div" align="center" color={light}>
          &copy; Copyright 2025 <br /> Full Code Software e Tecnologia <br />
          Todos os direitos Reservados
        </Typography>
      </StyledContent>

      {/* ======================================================================================================= */}

      {/* <StyledContent id="Session 6" style={{ alignItems: 'center', textAlign: 'center' }}>
        <Link to={PATH_EMPRESAS.fullcode}>
          <img
            src="images/logo-desenvolvedor.png"
            width={200}
            height={60}
            alt="Logo Desenvolvedor"
            style={{ borderRadius: '10px' }}
          />
        </Link>
      </StyledContent> */}
    </StyledContainer>
  );
}
